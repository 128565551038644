export function later(delay: number) {
 
  if(process.env.VUE_APP_FAST_DEBUG > 0) {
    delay = delay / 10;
  }

  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export async function post(url: string, params: any) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Token': process.env.VUE_APP_BACKEND_TOKEN,
    },
    body: JSON.stringify(params),
  };

  const response = await fetch(
    process.env.VUE_APP_BACKEND_URL + '/' + url,
    requestOptions
  );

  return response.json();
}
