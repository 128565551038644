<template>
  <ion-page>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col class="ion-text-center">
            {{ $t("user_info.title") }}
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col
            offset-xs="1"
            size-xs="10"
            offset-sm="2"
            size-sm="8"
            offset-md="4"
            size-md="4"
          >
            <template v-if="step == 0">
              <ion-item ref="gender">
                <ion-select
                  v-model="info.userInfo.gender"
                  :placeholder="$t('user_info.form.sex')"
                  interface="popover"
                >
                  <ion-select-option value="2">{{
                    $t("shared.woman")
                  }}</ion-select-option>
                  <ion-select-option value="1">{{
                    $t("shared.man")
                  }}</ion-select-option>
                </ion-select>
              </ion-item>

              <ion-item ref="gradeLevel">
                <ion-select
                  v-model="info.userInfo.gradeLevel"
                  :placeholder="$t('user_info.form.level_study')"
                  interface="popover"
                >
                  <ion-select-option v-for="n in 8" :key="n" :value="n">{{
                    $t("user_info.form.level_study_options." + n)
                  }}</ion-select-option>
                </ion-select>
              </ion-item>

              <ion-item ref="age">
                <ion-input
                  type="number"
                  min="0"
                  v-model="info.userInfo.age"
                  :placeholder="$t('user_info.form.age')"
                ></ion-input>
              </ion-item>

              <ion-item ref="yearsSchool">
                <ion-input
                  type="number"
                  min="0"
                  v-model="info.userInfo.yearsSchool"
                  :placeholder="$t('user_info.form.years_studied')"
                ></ion-input>
              </ion-item>

              <ion-item ref="codeRelatedTest">
                <ion-input
                  type="string"
                  v-model="info.codeRelatedTest"
                  :placeholder="$t('user_info.form.code')"
                ></ion-input>
              </ion-item>
              <ion-item ref="phone">
                <ion-input
                  type="string"
                  v-model="info.userInfo.phone"
                  :placeholder="$t('user_info.form.optional_phone')"
                ></ion-input>
              </ion-item>
              <ion-item ref="country">
                <ion-select
                  :placeholder="$t('user_info.form.country')"
                  interface="popover"
                  v-model="info.userInfo.country"
                >
                  <ion-select-option v-for="n in 238" :key="n" :value="n"
                    >{{ $t("form.countries." + n) }}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item ref="postalCode">
                <ion-input
                  type="string"
                  v-model="info.userInfo.postalCode"
                  :placeholder="$t('user_info.form.postalCode')"
                ></ion-input>
              </ion-item>
            </template>
            <template v-else>
              <ion-radio-group v-model="info.initialQuest.memoryLost">
                <ion-label style="font-size: 17px">{{
                  $t("form.questions.memory_lost")
                }}</ion-label>
                <ion-item ref="memoryLost1">
                  <ion-label>{{ $t("form.answers.yes") }}</ion-label>
                  <ion-radio
                    slot="start"
                    :value="$t('form.answers.yes')"
                  ></ion-radio>
                </ion-item>
                <ion-item ref="memoryLost2">
                  <ion-label>{{ $t("form.answers.no") }}</ion-label>
                  <ion-radio
                    slot="start"
                    :value="$t('form.answers.no')"
                  ></ion-radio>
                </ion-item>
              </ion-radio-group>

              <ion-radio-group v-model="info.initialQuest.worried">
                <ion-label style="font-size: 17px">{{
                  $t("form.questions.worried")
                }}</ion-label>
                <ion-item ref="worried1">
                  <ion-label>{{ $t("form.answers.yes") }}</ion-label>
                  <ion-radio
                    slot="start"
                    :value="$t('form.answers.yes')"
                  ></ion-radio>
                </ion-item>
                <ion-item ref="worried2">
                  <ion-label>{{ $t("form.answers.no") }}</ion-label>
                  <ion-radio
                    slot="start"
                    :value="$t('form.answers.no')"
                  ></ion-radio>
                </ion-item>
              </ion-radio-group>

              <ion-label style="font-size: 17px">{{
                $t("form.questions.since")
              }}</ion-label>
              <ion-item ref="since">
                <ion-input
                  type="number"
                  min="0"
                  v-model="info.initialQuest.since"
                >
                </ion-input>
                <span style="padding-left: 5px">{{
                  $t("user_info.form.years")
                }}</span>
              </ion-item>
            </template>

            <ion-button class="start_button" type="submit" @click="next">{{
              $t("user_info.start")
            }}</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
      <back-logos></back-logos>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import BackLogos from "@/components/BackLogos";
import { controller } from "@/services/Controller";
import { alertController } from "@ionic/vue";
import { Validation } from "@/services/Validation";

export default defineComponent({
  name: "UserInfoView",
  data() {
    return {
      step: 0,
      info: {
        codeRelatedTest: null,
        userInfo: {
          age: null,
          gender: null,
          yearsSchool: null,
          gradeLevel: null,
          phone: null,
          country: null,
          postalCode: null,
        },
        initialQuest: {
          memoryLost: null,
          worried: null,
          since: null,
        },
      },
    };
  },
  components: {
    BackLogos,
  },
  computed: {},
  mixins: [IonComponentsMixin],
  methods: {
    next: async function () {
      if (this.step == 0) {
        if (this.validate1()) this.step++;
      } else {
        if (this.validate2()) {
          let [correct, info] = await controller.createNewTest(
            this.info.codeRelatedTest,
            { ...this.info.userInfo },
            { ...this.info.initialQuest }
          );
          if (correct) {
            this.$router.replace("/tutorial");
          } else {
            await this.presentAlert(info.message, info.diff ? info.diff : 0);
            this.$router.replace("/home");
          }
        }
      }
    },
    validate1() {
      let i = this.info.userInfo;
      let valid = true;
      
      this.$refs.gender.$el.classList.value = '';
      if(!Validation.number(i.gender)) {
        valid = false;
        this.$refs.gender.$el.classList.value = 'invalid';
      }
      
      this.$refs.age.$el.classList.value = '';
      if(!Validation.number(i.age)) {
        valid = false;
        this.$refs.age.$el.classList.value = 'invalid';
      }

      this.$refs.yearsSchool.$el.classList.value = '';
      if(!Validation.number(i.yearsSchool)) {
        valid = false;
        this.$refs.yearsSchool.$el.classList.value = 'invalid';
      }

      this.$refs.gradeLevel.$el.classList.value = '';
      if(!Validation.number(i.gradeLevel)) {
        valid = false;
        this.$refs.gradeLevel.$el.classList.value = 'invalid';
      }

      this.$refs.country.$el.classList.value = '';
      if(!Validation.number(i.country)) {
        valid = false;
        this.$refs.country.$el.classList.value = 'invalid';
      }

      this.$refs.postalCode.$el.classList.value = '';
      if(!Validation.string(i.postalCode)) {
        valid = false;
        this.$refs.postalCode.$el.classList.value = 'invalid';
      }

      this.$refs.codeRelatedTest.$el.classList.value = '';
      if(!Validation.email(this.info.codeRelatedTest)) {
        valid = false;
        this.$refs.codeRelatedTest.$el.classList.value = 'invalid';
      }

      return valid;
    },
    validate2() {    
      let i = this.info.initialQuest;
      let valid = true;
      
      this.$refs.memoryLost1.$el.classList.value = '';
      this.$refs.memoryLost2.$el.classList.value = '';
      if(!Validation.required(i.memoryLost)) {
        valid = false;
        this.$refs.memoryLost1.$el.classList.value = 'invalid';
        this.$refs.memoryLost2.$el.classList.value = 'invalid';
      }

      this.$refs.worried1.$el.classList.value = '';
      this.$refs.worried2.$el.classList.value = '';
      if(!Validation.required(i.worried)) {
        valid = false;
        this.$refs.worried1.$el.classList.value = 'invalid';
        this.$refs.worried2.$el.classList.value = 'invalid';
      }

      this.$refs.since.$el.classList.value = '';
      if(!Validation.number(i.since)) {
        valid = false;
        this.$refs.since.$el.classList.value = 'invalid';
      }

      return valid;
    },
    presentAlert: async function (err, diff = 0) {
      let message = this.$t("alert." + err + "_message");

      if (diff > 0) {
        message +=
          Math.ceil(diff / (24 * 3600)) + this.$t("alert." + err + "_days");
      }

      const alert = await alertController.create({
        header: this.$t("alert." + err),
        message: message,
        buttons: [
          {
            text: this.$t("alert.ok"),
            handler: () => {
              this.$router.replace("/home");
            },
          },
        ],
      });

      await alert.present();
    },
  },
});
</script>

<style scoped>
ion-select {
  min-width: 100% !important;
  padding: 0px !important;
}

ion-input {
  --placeholder-opacity: 0.35;
}

.invalid {
  --border-color: red;
}

.start_button {
  margin-top: 12px;
  display: block;
  margin-right: auto;
  margin-left: auto;
}
</style>