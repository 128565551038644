<template>
  <ion-content>
    <ion-grid>
      <ion-row class="ion-text-center ion-align-items-center min_full_height">
        <ion-col size-md="10" offset-md="1">
          <span style="white-space: pre-line">{{ $t(data.lang) }}</span>
        </ion-col>
      </ion-row>
    </ion-grid>

    <back-logos></back-logos>
  </ion-content>
</template>

<script>
import { defineComponent } from "vue";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import BackLogos from "@/components/BackLogos";
import { later } from "@/services/Helper";
import { readAudio } from "@/services/ReadAudio";
import { QuestionMixin } from "@/mixins/QuestionMixin";
import { t } from "@/i18n";

export default defineComponent({
  name: "SimpleText",
  props: {
    data: {
      lang: String,
    },
  },
  mixins: [IonComponentsMixin, QuestionMixin],
  components: {
    BackLogos,
  },
  methods: {
    init: async function (current_question_index) {
      this.questionIndex = current_question_index;
      await later(100);
      await readAudio.start(t(this.data.lang));
      await later(3000);
      await this.next();
    },
  },
});
</script>

<style lang="less" scoped>
</style>

