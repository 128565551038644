import { QuestionSelectFace } from '@/models/QuestionSelectFace';

export class AnswerSelectFace {
  correctImg: string;
  selectedImg: string;
  isImgCorrect: number;
  type = 'SelectFace';

  constructor(question: QuestionSelectFace, public imgSelected: string) {
    const correctPerson = question.person.getReal();

    this.correctImg = correctPerson.img;
    this.selectedImg = imgSelected;
    this.isImgCorrect = 0;

    if (imgSelected === correctPerson.img) {
      this.isImgCorrect = 1;
    }
  }
}
