const QuestionMixin = {
    emits: ['answered'],
    data() {
        return {
          questionIndex: null,
        };
      },
    methods: {
        init: async function init(current_question_index: number){
            this.questionIndex = current_question_index;
        },
        next: async function () {
            // answer null by default
            this.$emit('answered', {questionIndex: this.questionIndex, answer: null});
        },
    }
};

export {QuestionMixin};