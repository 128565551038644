import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import { controller } from '@/services/Controller';

import { IonicVue } from '@ionic/vue';
import { setupI18n } from '@/i18n';
import ClientJS from 'clientjs';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';


/* Theme variables */
import '@/theme/global.css';
import '@/theme/variables.css';
import { initStorage } from './services/Storage';


(async () => {

  // we must init storage before anything else
  await initStorage();
  const i18n = await setupI18n();
  await controller.init();

  const app = createApp(App).use(IonicVue).use(i18n).use(router);

  app.config.globalProperties.clientjs = new (ClientJS as any).ClientJS() as ClientJS;

  window.onunhandledrejection = (error) => {
    console.error(error);
    controller.sendError(error.reason + ' | ' + error.target);
    error.preventDefault();
  }

  app.config.errorHandler = (err, vm, info) => {
    if (err instanceof Error) {
      controller.sendError(err.message + ' | ' + info + ' | ' + err.cause + ' | ' + err.stack + ' | ');
    }
    
    console.error(err);
  }

  router.isReady().then(() => {
    app.mount('#app');
  });
  
})();
