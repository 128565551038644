import { Question } from '@/models/Question';
import { QuestionRememberFace } from '@/models/QuestionRememberFace';
import { QuestionSelectFace } from '@/models/QuestionSelectFace';
import { QuestionSelectNameAndJob } from '@/models/QuestionSelectNameAndJob';
import { QuestionSimpleText } from '@/models/QuestionSimpleText';
import { QuestionImgToNameAndProfession } from '@/models/QuestionImgToNameAndProfession';
import { QuestionWait } from '@/models/QuestionWait';
import { getCurrentLocale } from '@/i18n';
import { Person } from '@/models/Person';
import { QuestionBreakForm } from '@/models/QuestionBreakForm';
import { generateFormQuestions } from '@/services/FormDefinition';

async function loadPeople(
  testLetter: string,
  listNumber: number
): Promise<Person[]> {
  const lang = getCurrentLocale();
  const num = listNumber === 1 ? 'first' : 'second';
  const letter = testLetter === 'B' ? 'b_' : '';

  const response: any = await fetch(
    `/assets/people/${num}_order_list_${letter}${lang}.json`
  );

  const result = await response.json();

  const people: Person[] = [];
  result.people.forEach((p: any) => {
    people.push(new Person(p));
  });

  return people;
}

export async function defineExam(testType: 'A' | 'B'): Promise<Question[]> {
  const examDefinition: Question[] = [];

  const firstPeople = await loadPeople(testType, 1);

  const secondPeople = await loadPeople(testType, 2);

  examDefinition.push(new QuestionSimpleText('exam.initial_remember_face'));

  // Order: 1
  firstPeople.forEach((person: Person) => {
    examDefinition.push(new QuestionRememberFace(person));
  });

  examDefinition.push(new QuestionSimpleText('exam.img_to_name_and_job'));

  // Order: 1
  firstPeople.forEach((person) => {
    examDefinition.push(new QuestionImgToNameAndProfession(person));
  });

  examDefinition.push(new QuestionSimpleText('exam.second_test_message'));

  secondPeople.forEach((person) => {
    examDefinition.push(new QuestionRememberFace(person));
  });

  examDefinition.push(new QuestionSimpleText('exam.img_to_name_and_job'));

  secondPeople.forEach((person) => {
    examDefinition.push(new QuestionImgToNameAndProfession(person));
  });

  examDefinition.push(new QuestionSimpleText('exam.rcp_first_message'));

  // Order: 2
  examDefinition.push(new QuestionWait(60 * 2, false, false));

  examDefinition.push(new QuestionSimpleText('exam.rcp_second_message'));

  // Order: 1
  firstPeople.forEach((person) => {
    examDefinition.push(new QuestionImgToNameAndProfession(person));
  });
  

  examDefinition.push(new QuestionSimpleText('exam.break_form'));
  const form = await generateFormQuestions();

  const time = process.env.VUE_APP_FAST_DEBUG ? 1 : 15;

  examDefinition.push(
    new QuestionBreakForm(
      new QuestionWait(60 * time, true, true),
      form
    )
  );

  // Order: 2
  secondPeople.forEach((person) => {
    examDefinition.push(new QuestionSimpleText('exam.last_test_message'));
    examDefinition.push(new QuestionSelectFace(person));
    examDefinition.push(new QuestionImgToNameAndProfession(person));
    examDefinition.push(new QuestionSelectNameAndJob(person));
  });

  return examDefinition;
}


