<template>
  <ion-page>
    <ion-content>
      <ion-grid>
        <ion-row class="ion-text-center ion-align-items-center min_full_height">
          <ion-col size="6">
            {{ $t("voice_test.message") }}
          </ion-col>
          <ion-col size="6">
            <ion-row>
              <ion-col>
                <img :src="steps[currentStep].img" class="test-image" />
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <span :style="{ color: messageColor }" v-html="mostProbableMessage"></span>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <record-audio
                  :helpText="false"
                  @audioRecorded="audioRecorded($event)"
                ></record-audio>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import { later } from "@/services/Helper";
import RecordAudio from "@/components/RecordAudio";
import { t } from "@/i18n";

export default defineComponent({
  name: "VoiceTestView",
  components: {
    RecordAudio,
  },
  mixins: [IonComponentsMixin],
  data() {
    return {
      steps: [
        { answer: "voice_test.chair", img: "/assets/img/others/chair.jpg" },
        { answer: "voice_test.banana", img: "/assets/img/others/banana.jpg" },
        {
          answer: "voice_test.elephant",
          img: "/assets/img/others/elephant.png",
        },
      ],
      currentStep: 0,
      mostProbableMessage: "&nbsp;",
      messageColor: "black",
    };
  },
  methods: {
    next: async function () {
      this.$router.replace("/exam");
    },
    audioRecorded: async function(messageOptions) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias

      if (messageOptions.length === 0) {
        return;
      }

      this.mostProbableMessage = t(
        this.steps[this.currentStep].answer
      );

      this.messageColor = "red";

      messageOptions.forEach((element) => {
        if (
          element ===
          t(this.steps[this.currentStep].answer)
        ) {
          this.messageColor = "green";
        }
      });

      await later(3000);

      if (this.currentStep >= this.steps.length - 1) {
        await later(3000);
        this.$router.replace("/exam");
      } else {
        this.messageColor = 'black';
        this.mostProbableMessage = '&nbsp;';
        this.currentStep++;
      }
    },
  },
});
</script>