import { post } from '@/services/Helper';
import { getCurrentLocale } from '@/i18n';

class ReadAudio {
  currentBrowserSound: HTMLAudioElement | null = null;
  toPlay = false;

  async start(text: string) {
    await this.readAudioWeb(text);
  }

  stop() {
    this.toPlay = false;
    if (this.currentBrowserSound) {
      this.currentBrowserSound.pause();
    }
  }

  async readAudioWeb(text: string) {
    const localUrl = 'readText';
    this.toPlay = true;

    const data = {
      text,
      locale: getCurrentLocale()
    };

    const audio = await post(localUrl, data);

    if(!this.toPlay) return;

    this.currentBrowserSound = new Audio(
      'data:audio/wav;base64,' + audio.audioContent
    );

    try {
      await this.playBrowser(this.currentBrowserSound);
    } catch (e) {
      console.error('Error play');
      console.error(e);
    }

    this.toPlay = false;
  }

  async playBrowser(audio: HTMLAudioElement) {
    await audio.play();
  }
}

export const readAudio = new ReadAudio();
