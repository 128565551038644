import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import HomeView from '@/views/HomeView.vue';
import LegalTextView from '@/views/LegalTextView.vue';
import UserInfoView from '@/views/UserInfoView.vue';
import TutorialView from '@/views/TutorialView.vue';
import VoiceTestView from '@/views/VoiceTestView.vue';
import ExamView from '@/views/ExamView.vue';
import CreditsView from '@/views/CreditsView.vue';
import FinishedView from '@/views/FinishedView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: HomeView
  },
  {
    path: '/legal-text',
    component: LegalTextView
  },
  {
    path: '/user',
    component: UserInfoView
  },
  {
    path: '/tutorial',
    component: TutorialView
  },
  {
    path: '/voice-test',
    component: VoiceTestView
  },
  {
    path: '/exam',
    component: ExamView
  },
  {
    path: '/credits',
    component: CreditsView
  },
  {
    path: '/finished',
    component: FinishedView
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router
