<template>
  <div>{{ min }} : {{ sec }}</div>
</template>

<script>
import { later } from "@/services/Helper";

export default {
  emits: ["timeCompleted"],
  props: {
    time: {
      type: Number,
    },
  },
  data() {
    return {
      now: this.time
    };
  },
  computed: {
    min() {
      let m = Math.trunc(this.now / 60);
      return m > 9 ? m : "0" + m;
    },
    sec() {
      let s = this.now % 60;
      return s > 9 ? s : "0" + s;
    },
  },
  mounted: async function () {
    while (this.now > 0) {
      await later(1000);
      this.now--;
    }

    this.$emit("timeCompleted");
  },
};
</script>