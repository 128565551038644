<template>
  <ion-page>
    <ion-content>
      <component
        ref="question"
        v-if="currentQuestion != null"
        :is="currentQuestion.getComponent()"
        :data="currentQuestion.getData()"
        @answered="nextStep"
      ></component>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, nextTick } from "vue";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import { controller } from "@/services/Controller";

import { Question } from "@/models/Question";
import EmptyLoad from "@/components/EmptyLoad";

import SimpleText from "@/components/questions/SimpleText.vue";
import RememberFace from "@/components/questions/RememberFace.vue";
import ImgToNameAndProfession from "@/components/questions/ImgToNameAndProfession.vue";
import WaitTime from "@/components/questions/WaitTime.vue";
import SelectFace from "@/components/questions/SelectFace.vue";
import SelectNameAndJob from "@/components/questions/SelectNameAndJob.vue";
import BreakForm from "@/components/questions/BreakForm.vue";

export default defineComponent({
  name: "ExamView",
  components: {
    SimpleText,
    RememberFace,
    ImgToNameAndProfession,
    WaitTime,
    SelectFace,
    SelectNameAndJob,
    EmptyLoad,
    BreakForm
  },
  mixins: [IonComponentsMixin],
  data() {
    return {
      currentQuestion: new Question(),
      lastIndex: -1,
      finished: false,
    };
  },
  ionViewDidEnter: async function () {
    this.advance();
  },
  methods: {
    advance: async function () {
      let questionIndex;
      const exam = await controller.getCurrentQuestion();
      
      if (exam == null) {
        this.$router.replace("/home");
        return;
      }
      
      [this.finished, this.currentQuestion, questionIndex] = exam;
      
      if (this.finished) {
        await controller.clearPreviousTest();
        this.$router.replace("/credits");
      } else {
        if (questionIndex > this.lastIndex) {
          await nextTick();
          this.$refs.question.init(questionIndex);
          this.lastIndex = questionIndex;
        }
      }
    },
    nextStep: async function (event) {
      await controller.addAnswer(event.questionIndex, event.answer);
      await this.advance();
    },
  },
});
</script>