import { QuestionSelectNameAndJob } from '@/models/QuestionSelectNameAndJob';

export class AnswerSelectNameAndJob {
  correctName: string;
  correctJob: string;
  isNameCorrect: number;
  isJobCorrect: number;
  selectedName: string;
  selectedJob: string;
  type = 'SelectNameAndJob';

  constructor(
    question: QuestionSelectNameAndJob,
    public name: string,
    public job: string
  ) {
    const correctPerson = question.person.getReal();

    this.isNameCorrect = 0;
    if (this.name === correctPerson.name) {
      this.isNameCorrect = 1;
    }

    this.isJobCorrect = 0;
    if (this.job === correctPerson.job) {
      this.isJobCorrect = 1;
    }

    this.correctName = correctPerson.name;
    this.correctJob = correctPerson.job;
    this.selectedName = this.name;
    this.selectedJob = this.job;
  }
}
