import { Question } from '@/models/Question';
import { Person } from '@/models/Person';

export class QuestionSelectNameAndJob extends Question {

  constructor(public person: Person) {
    super();
  }

  getComponent(): string {
    return 'SelectNameAndJob';
  }
}
