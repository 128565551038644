<template>
  <div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import { QuestionMixin } from "@/mixins/QuestionMixin";

export default defineComponent({
  name: "EmptyLoad",
  components: {},
  mixins: [IonComponentsMixin, QuestionMixin]
});
</script>

<style lang="less" scoped>
</style>