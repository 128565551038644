<template>
  <ion-grid>
    <ion-row class="ion-align-items-center">
      <ion-col
        v-if="helpText"
        offset-xs="1"
        size-xs="10"
        offset-sm="2"
        size-sm="3"
        class="help_text"
      >
        {{ $t("record_audio.help") }}
      </ion-col>
      <ion-col
        v-if="helpText"
        class="ion-align-self-center"
        offset-xs="5"
        offset-sm="0"
        :size="2"
      >
        <ion-button
          class="record_button"
          shape="round"
          size="small"
          @click="start"
          ><ion-icon :icon="mic"></ion-icon
        ></ion-button>
      </ion-col>
      <ion-col
        v-if="!helpText"
        class="ion-align-self-center"
        offset-sm="0"
        :size="12"
      >
        <ion-button
          class="record_button"
          shape="round"
          size="small"
          @click="start"
          :disabled="button_disabled"
          ><ion-icon :icon="mic"></ion-icon
        ></ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { defineComponent } from "vue";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import { alertController } from '@ionic/vue';
import { recordAudio } from "@/services/RecordAudio";
import { later } from "@/services/Helper";
import { mic } from "ionicons/icons";
import { t } from "@/i18n";

export default defineComponent({
  name: "RecordAudio",
  components: {},
  props: {
    helpText: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      alert: null,
      mic: mic,
      button_disabled: false,
      reset_counter: 0
    };
  },
  methods: {
    start: async function () {
      this.reset_counter = 0;
      await this.presentAlert();
      await this.record();
      await this.hideAlert();
    },
    record: async function () {
      let result, error;

      this.button_disabled = true;

      try {
        [result, error] = await recordAudio.start();
      }
      catch(e) {
        result = null;
        console.error(e)
        error = 'alert.unhandled_error';
      }

      if (result != null && result != undefined) {
        result = result.map((element) => element.toLowerCase());
        this.$emit('audioRecorded',result);
      } else {
        this.alert.header = t(error);
        await later(2000);
        this.alert.header = t("alert.recording");
        this.reset_counter++;
        if(this.reset_counter < 6) {
          await this.record();
        }
        else {
          location.reload();
        }
        
      }

      this.button_disabled = false;
    },
    presentAlert: async function() {
      let iosButton = [];

      this.alert = await alertController.create({
        header: t("alert.recording"),
        buttons: iosButton,
        backdropDismiss: false,
      });

      await this.alert.present();
    },
    hideAlert: async function() {
      await this.alert.dismiss();
    }
  },
  mixins: [IonComponentsMixin],
});
</script>

<style lang="less" scoped>
.record_button {
  width: 60px;
  height: 60px;
  --background: #fd5d58;
  --border-widthV: 0;
}

.help_text {
  font-size: 14px;
}
</style>