import { getCurrentLocale } from '@/i18n';
import {post} from '@/services/Helper';
import {later} from '@/services/Helper';
import {TtsLanguages} from '@/services/Languages';
import RecordRTC from 'recordrtc';

class RecordAudio {
  recording = false;

  webRecorder: any;

  async stop() {
    if (this.recording) {
      this.recording = false;
      await this.webRecorder.stopRecording();
      //this.webRecorder.resolve();
    }
  }

  async start(): Promise<any> {
    const lang = getCurrentLocale();
    if(process.env.VUE_APP_FAST_DEBUG > 0) {
      await later(100);
      if(Math.random() < 0.1) {
        return [null, 'alert.cant_read_microphone'];
      }
      console.log('Simulated response');
      return [['no sé'], null];
    }

    this.recording = true;

    let stream = null;

    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      });
    }
    catch(err) {
      console.error("Can't access microphone")
    }

    if(!stream) return [null, 'alert.cant_read_microphone'];

    const recorder = new RecordRTC.RecordRTCPromisesHandler(stream, {
        type: 'audio',
        recorderType: RecordRTC.StereoAudioRecorder,
        mimeType: "audio/wav",
        numberOfAudioChannels: 1
    });

    recorder.startRecording();

    await later(3000);

    await recorder.stopRecording();
    const recordedBlob = await recorder.getBlob();

    if (!recordedBlob) {
      return [null, 'alert.cant_read_microphone'];
    }

    const reader = new FileReader();
    await this.readerReadBlob(reader, recordedBlob);

    const base64data = (reader.result as string).split(',')[1];

    const data = {
      base64data,
      locale: lang
    };

    const localUrl = 'transcriptAudio';

    const responsevoice = await post(localUrl, data);

    if(responsevoice == null) {
      return [null, 'alert.cant_reach_server'];
    }

    if (!responsevoice.results) {
      return [null, 'alert.not_understood'];
    }

    const results = responsevoice.results[0].alternatives.map(
      (alternative: any) => alternative.transcript
    );

    if (results.length === 0) {
      return [null, 'alert.not_understood'];
    }

    return [results.slice(0, 3), null];
  }

  async startRecordBrowser() {
      this.webRecorder.startRecording();
      await later(4000);
      await this.stop();
  }

  readerReadBlob(reader: any, recordedBlob: any) {
    return new Promise((resolve) => {
      reader.onloadend = () => resolve('');
      reader.readAsDataURL(recordedBlob);
    });
  }
}

export const recordAudio = new RecordAudio();