import {
    IonPage,
    IonContent,
    IonGrid,
    IonCol,
    IonRow,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonButton,
    IonCheckbox,
    IonInput,
    IonRadio,
    IonRadioGroup,
    IonIcon,
} from "@ionic/vue";

const IonComponentsMixin = {
    components: {
        IonPage,
        IonContent,
        IonGrid,
        IonCol,
        IonRow,
        IonLabel,
        IonSelect,
        IonSelectOption,
        IonItem,
        IonButton,
        IonCheckbox,
        IonInput,
        IonRadio,
        IonRadioGroup,
        IonIcon
    }
};

export {IonComponentsMixin};