<template>
  <div class="bottom_right">
    <ion-grid>
      <ion-row class="ion-text-end">
        <ion-col>
          <img class="logo" src="@/assets/img/logos/logo_face_bn.png">
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";

export default defineComponent({
  name: "BackLogos",
  components: {},
  mixins: [IonComponentsMixin]
});
</script>

<style lang="less" scoped>
.bottom_right {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: -1;

  img {
    height: 10vh;
    max-height: 100px;
    padding: 2px;
  }

  display: none;

  @media (min-height: 400px) and (min-width: 400px) {
    display: block;
  }

  @media (min-width: 650px) {
    display: block;
  }
}
</style>