import { Question } from '@/models/Question';

export class QuestionSimpleText extends Question {

  constructor(public lang: string) {
    super();
  }

  getComponent(): string {
    return 'SimpleText';
  }
}
