<template>
  <ion-page>
    <ion-content>
      <ion-grid>
        <ion-row
          class="ion-text-left ion-align-items-center min_full_height"
          style="font-size: 22px"
        >
          <ion-col size-md="10" offset-md="1">
            <span style="white-space: pre-line">{{ $t(message) }}</span>
            <form>
              <ion-item style="margin-top: 30px">
                <ion-checkbox
                  slot="start"
                  v-model="consent.consent1"
                  value=""
                  class="checkbox"
                ></ion-checkbox>
                <ion-label>{{ $t("legal.consent_1") }}</ion-label>
              </ion-item>
              <ion-item style="margin-top: 30px">
                <ion-checkbox
                  slot="start"
                  value=""
                  v-model="consent.consent2"
                  class="checkbox"
                ></ion-checkbox>
                <ion-label>{{ $t("legal.consent_2") }}</ion-label>
              </ion-item>
              <ion-row class="ion-justify-content-center" style="padding-top: 10px">
                <ion-button
                  class="start_button"
                  :disabled="disabled"
                  @click="next"
                >
                  {{ $t("form.buttons.continue") }}
                </ion-button>
              </ion-row>
            </form>
          </ion-col>
        </ion-row>
      </ion-grid>
      <back-logos></back-logos>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import BackLogos from "@/components/BackLogos";
import { later } from "@/services/Helper";
import { readAudio } from "@/services/ReadAudio";
import { controller } from "@/services/Controller";


export default defineComponent({
  name: "LegalTextView",
  components: {
    BackLogos
  },
  mixins: [IonComponentsMixin],
  data() {
    return {
        message: "legal.legal_text",
        consent: {
            consent1: false,
            consent2: false
        },
        disabled: true,
    };
  },
  ionViewDidEnter: async function() {
    await readAudio.start(this.$t(this.message));
    await later(5000);
    this.disabled = false;
  },
  ionViewWillLeave: async function(){
    if(readAudio) await readAudio.stop();
  },
  methods: {
      next: async function() {
        if(readAudio) await readAudio.stop();
        await controller.setUserConsent({...this.consent});
        this.$router.replace('/user');
      }
  },
});
</script>