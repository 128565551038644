<template>
  <ion-content>
    <ion-grid>
      <ion-row class="ion-text-center ion-align-items-center min_full_height">
        <ion-col>
          <ion-row>
            <ion-col v-show="allLoaded">
              {{ $t("select_face.question") }}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col v-for="n in 3" :key="n" size="4">
              <img
                class="image"
                @click="select(data.person.getOptions().img[n - 1])"
                :src="getUrl(n)"
                v-show="allLoaded"
                @load="loaded"
              />
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
    <back-logos></back-logos>
  </ion-content>
</template>

<script>
import { defineComponent } from "vue";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import BackLogos from "@/components/BackLogos";
import { QuestionMixin } from "@/mixins/QuestionMixin";
import { AnswerSelectFace } from "@/models/AnswerSelectFace";

export default defineComponent({
  name: "SelectFace",
  props: {
    data: {
      data: Object,
    },
  },
  data: function() {
    return {allLoaded: false, images: 0}
  },
  mixins: [IonComponentsMixin, QuestionMixin],
  components: {
    BackLogos,
  },
  methods: {
    select: async function (img) {
      const answer = new AnswerSelectFace(this.data, img);
      this.$emit('answered', {questionIndex: this.questionIndex, answer: answer});
    },
    loaded: function() {
      this.images += 1;
      if (this.images === 3) {
        this.allLoaded = true;
      }
    },
    getUrl: function(n) {
      return '/assets/img/faces/' + this.data.person.getOptions().img[n - 1] + '.jpg';
    }
  }
});
</script>

<style lang="less" scoped>

img {
    max-width: 100%;
    padding: 5vh;
    cursor: pointer;
}

@media (min-height: 500px) {
    ion-row {
        padding-bottom: 5vh;
    }
}
</style>

