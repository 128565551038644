import { post } from '@/services/Helper';
import { defineExam } from '@/services/ExamDefinition';
import { Question } from '@/models/Question';
import { storage } from '@/services/Storage';
import { getCurrentLocale } from '@/i18n';

type UserInfo = {
  age?: number;
  gender?: number;
  yearsSchool?: number;
  gradeLevel?: number;
  country?: string;
  postalCode?: string;
};

type Consent = {
  consent1: boolean;
  consent2: boolean;
};

type InitialQuest = {
  memoryLost: string;
  worried: string;
  since: number;
};

class Controller {
  info: {
    codeRelatedTest?: string;
    consent?: Consent;
    userInfo?: UserInfo;
    initialQuest?: InitialQuest;
    locale?: string;
  } = {};

  currentExam: null | {
    examDefinition: Question[];
    currentQuestionIndex: number;
    testType: 'A' | 'B';
  } = null;

  testId: null | number = null;
  code: null | string = null;

  async init() {
    this.testId = await storage?.get('testId');
    this.code = await storage?.get('code');
  }

  isTestStarted() {
    return this.testId && this.code;
  }

  async setUserConsent(consent: Consent) {
    this.info.consent = consent;
    await storage?.set('consent', consent);
  }

  async createNewTest(
    codeRelatedTest: string,
    userInfo: UserInfo,
    initialQuest: InitialQuest
  ) {
    this.info.codeRelatedTest = codeRelatedTest;
    this.info.userInfo = userInfo;
    this.info.initialQuest = initialQuest;
    this.info.consent = await storage?.get('consent');
    this.info.locale = getCurrentLocale();

    const newTest = await post('newTest', this.info);

    if(newTest.message) {
      return [false, newTest];
    }

    await storage?.set('code', codeRelatedTest);
    await storage?.set('testId', newTest.test_id);

    this.testId = newTest.test_id;
    this.code = codeRelatedTest;

    return [true, newTest];
  }

  async clearPreviousTest() {
    await storage?.remove('code');
    await storage?.remove('testId');
  }

  async generateExam(): Promise<null | {
    examDefinition: Question[];
    currentQuestionIndex: number;
    testType: 'A' | 'B';
  }> {

    if(!this.testId && !this.code) {
      console.error('Test id should be already defined');
      return null;
    }

    const test = await post('getTest', {testId: this.testId, code: this.code });

    if(!test) {
      return null;
    }

    return {
      examDefinition: await defineExam(test.type),
      currentQuestionIndex: test.current_question_index,
      testType: test.type,
    }
  }

  async getCurrentQuestion() {
    if (this.currentExam == null) {
      this.currentExam = await this.generateExam();
    }

    if (this.currentExam == null) {
      this.clearPreviousTest();
      return null;
    }

    if (
      this.currentExam.currentQuestionIndex >=
      this.currentExam.examDefinition.length
    ) {
      this.clearPreviousTest();
      return [true, null, null];
    }

    return [
      false,
      this.currentExam.examDefinition[this.currentExam.currentQuestionIndex],
      this.currentExam.currentQuestionIndex,
    ];
  }

  async addAnswer(questionIndex: number, answer: any) {
    if (this.currentExam && questionIndex == this.currentExam.currentQuestionIndex) {
      this.currentExam.currentQuestionIndex++;

      const result = await post('answer', {
        answer,
        testId: this.testId,
        code: this.code,
        currentQuestionIndex: this.currentExam.currentQuestionIndex - 1,
        finished: this.currentExam.examDefinition.length == this.currentExam.currentQuestionIndex,
        percentageCompleted: Math.round((this.currentExam.currentQuestionIndex + 1) * 100 / this.currentExam.examDefinition.length)
      });

    } else {
      console.error('Exam should be initialized already or answer already sent');
    }
  }

  async sendError(error: any) {
    try {
      await post('error', { error, testId: this.testId, code: this.code });
    } catch (ex) {
      console.error('Could not connect to server');
      console.error(ex);
    }
  }
}

export const controller = new Controller();
