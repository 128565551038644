<template>
  <ion-grid>
    <ion-row class="ion-text-center ion-align-items-center min_full_height">
      <ion-col>
        <ion-row>
          <ion-col>
            {{ $t("select_name_and_job.question") }}
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <img
              class="image"
              :src="'/assets/img/faces/' + data.person.getReal().img + '.jpg'"
            />
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col
            class="clicable bg-white"
            v-for="n in 3"
            :key="n"
            :class="{
              selected: name === data.person.getOptions().names[n - 1],
            }"
            @click="nameSelected(data.person.getOptions().names[n - 1])"
            size="4"
          >
            {{ data.person.getOptions().names[n - 1] }}
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col
            class="clicable bg-white"
            v-for="n in 3"
            :key="n"
            :class="{ selected: job === data.person.getOptions().jobs[n - 1] }"
            @click="jobSelected(data.person.getOptions().jobs[n - 1])"
            size="4"
          >
            {{ data.person.getOptions().jobs[n - 1] }}
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
  <back-logos></back-logos>
</template>

<script>
import { defineComponent } from "vue";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import BackLogos from "@/components/BackLogos";
import { QuestionMixin } from "@/mixins/QuestionMixin";
import { AnswerSelectNameAndJob } from "@/models/AnswerSelectNameAndJob";

export default defineComponent({
  name: "SelectnameAndJob",
  props: {
    data: {
      data: Object,
    },
  },
  mixins: [IonComponentsMixin, QuestionMixin],
  components: {
    BackLogos,
  },
  data: function () {
    return { name: null, job: null, questionIndex: null };
  },
  methods: {
    checkAnswer: function () {
      if (this.name != null && this.job != null) {
        this.$emit(
          "answered",
          {questionIndex: this.questionIndex, answer: new AnswerSelectNameAndJob(this.data, this.name, this.job)}
        );
      }
    },
    nameSelected: function (name) {
      this.name = name;
      this.checkAnswer();
    },
    jobSelected: function (job) {
      this.job = job;
      this.checkAnswer();
    },
  },
});
</script>

<style lang="less" scoped>
img {
  max-height: 50vh;
}

.selected {
  font-weight: bold;
}

.bg-white {
  background: white;
}

.clicable:hover {
  cursor: pointer;
}

@media (min-height: 500px) {
  ion-row {
    padding-bottom: 5vh;
  }
}
</style>

