export class Person {
  names;
  jobs;
  imgs;
  loadedData;

  constructor(loadedData: any) {
    this.loadedData = loadedData;
    this.names = [loadedData.name1, loadedData.name2, loadedData.name3];
    this.jobs = [
      loadedData.profession1,
      loadedData.profession2,
      loadedData.profession3,
    ];
    this.imgs = [loadedData.image1, loadedData.image2, loadedData.image3];
  }

  getReal() {
    return {
      name: this.loadedData.name,
      job: this.loadedData.profession,
      img: this.imgs[this.loadedData.correct_image_index],
    };
  }

  getOptions() {
    return {
      names: this.names,
      jobs: this.jobs,
      img: this.imgs,
    };
  }
}
