<template>
  <ion-page>
    <ion-content>
      <ion-grid>
        <ion-row
          class="small_padding_top ion-text-center ion-align-items-center"
        >
          <ion-col size-xs="12" size-sm="4">
            <ion-row>
              <ion-col size-sm="12" size-xs="4">
                <img class="logo" src="@/assets/img/logos/logo_face_sol.png" />
              </ion-col>
            </ion-row>
          </ion-col>

          <ion-col offset-xs="1" offset-sm="0" size-xs="10" size-sm="7">
            <ion-row>
              <ion-col size="12" class="blue_box">
                {{ $t("credits.face_collaborators") }}
              </ion-col>
              <ion-col size="12" class="blue_box">
                {{ $t("credits.editorial_glosa") }}
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import { later } from "@/services/Helper";

export default defineComponent({
  name: "CreditsView",
  components: {},
  mixins: [IonComponentsMixin],
  data() {
    return {};
  },
  ionViewWillEnter: async function () {
    this.next();
  },
  methods: {
    next: async function () {
      await later(4000);
      this.$router.replace('/finished');
    },
  },
});
</script>

<style lang="less" scoped>
.logo {
  min-height: 20px;
  height: 12vh;
  object-fit: contain;
}

.small_padding_top {
  padding-top: 6vh;
}

.blue_box {
  background: rgb(5, 105, 251);
  background: linear-gradient(
    180deg,
    rgb(56, 131, 245) 0%,
    rgba(186, 186, 236, 1) 100%
  );
  padding: 2vh;

  font-size: 1.1rem;
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media (min-height: 400px) and (min-width: 900px) {
    font-size: 1.4rem;
  }

  @media (min-height: 630px) and (min-width: 625px) {
    font-size: 1.4rem;
  }

  @media (min-height: 576px) and (min-width: 910px) {
    font-size: 1.6rem;
  }
}
</style>