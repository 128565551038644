import { Question } from '@/models/Question';
import { storage } from '@/services/Storage';

export class QuestionWait extends Question {

  constructor(public time: number, public breakForm: boolean, public fromTime: boolean) {
    super();
  }

  async getTime() {
    const initialTime = this.time;
    if(this.fromTime) {
      const storageTime = await storage?.get('time');
      if(storageTime) {
        this.time = Math.round(this.time - (Date.now() - storageTime) / 1000);
      }
    }
    return Math.max(initialTime/10, this.time);

  }

  getComponent(): string {
    return 'WaitTime';
  }
}
