<template>
  <ion-page>
    <ion-content>
      <ion-grid>
        <ion-row class="ion-text-center ion-align-items-center fill_height">
          <ion-col>
            <ion-row>
              <ion-col>
                {{ $t("tutorial.title") }}
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col offset="2" size="8" style="margin-top: 50px">
            <ion-row class="video_wrap">
              <iframe
                width="666"
                height="375"
                :src="secondVideo"
                allow="autoplay"
              ></iframe>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>

      <back-logos></back-logos>

      <div class="top_right">
        <ion-button v-if="showButton" color="medium" @click="next">{{
          $t("tutorial.continue")
        }}</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import BackLogos from "@/components/BackLogos";
import {later} from "@/services/Helper";

export default defineComponent({
  name: "TutorialView",
  components: {
    BackLogos
  },
  mixins: [IonComponentsMixin],
  data() {
    return {
      showButton: false,
      secondVideo: this.$t("tutorial.second_vid"),
    };
  },
  mounted: async function() {
    await later(80000);
    this.showButton = true;
  },
  methods: {
    next() {
      this.$router.replace('/voice-test');
    }
  }
});
</script>

<style scoped>
.top_right {
    position: fixed;
    top: 2vh;
    right: 2vw;
}

.video_wrap {
    display: flex; 
    justify-content: center;
}

.separator {
    background-color: black;
    width: 500px;
}

.dontShow {
    display: none;
}

@media(max-width:400px){
    ion-button {
        font-size: 5px;
    }
}
@media(max-width:830px){
    iframe {
        width: 70vw;
    }

    .separator {
        width: 60vw;
    }
}

</style>