import { QuestionForm } from '@/models/QuestionForm';

export async function generateFormQuestions(): Promise<QuestionForm[][]> {
    const formDefinition = [];
  
      formDefinition.push([
        new QuestionForm(
          'form.questions.auditive',
          ['form.answers.yes', 'form.answers.no'],
          false,
          '',
          'form.names.auditive'
        ),
        new QuestionForm(
          'form.questions.visual',
          ['form.answers.yes', 'form.answers.no'],
          false,
          '',
          'form.names.visual'
        ),
        new QuestionForm(
          'form.questions.memory',
          ['form.answers.yes', 'form.answers.no'],
          false,
          '',
          'form.names.memory'
        ),
      ]);
  
      formDefinition.push([
        new QuestionForm(
          'form.questions.neurology',
          ['form.answers.yes', 'form.answers.no'],
          true,
          'form.placeholders.which',
          'form.names.neurology'
        ),
        new QuestionForm(
          'form.questions.psychiatry',
          ['form.answers.yes', 'form.answers.no'],
          true,
          'form.placeholders.if_so_why',
          'form.names.psychiatry'
        ),
      ]);
  
      formDefinition.push([
        new QuestionForm('form.questions.diseases', [], false, '', ''),
        new QuestionForm(
          'form.questions.hypertension',
          ['form.answers.yes', 'form.answers.no'],
          false,
          '',
          'form.names.hypertension'
        ),
        new QuestionForm(
          'form.questions.diabetes',
          ['form.answers.yes', 'form.answers.no'],
          false,
          '',
          'form.names.diabetes1'
        ),
        new QuestionForm(
          'form.questions.cholesterol',
          ['form.answers.yes', 'form.answers.no'],
          false,
          '',
          'form.names.cholesterol'
        ),
      ]);
  
      formDefinition.push([
        new QuestionForm(
          'form.questions.ictus',
          ['form.answers.yes', 'form.answers.no'],
          false,
          '',
          'form.names.ictus'
        ),
        new QuestionForm(
          'form.questions.heart_problems',
          ['form.answers.yes', 'form.answers.no'],
          false,
          '',
          'form.names.heart_problems'
        ),
      ]);
  
      formDefinition.push([
        new QuestionForm(
          'form.questions.smoker',
          ['form.answers.yes', 'form.answers.no'],
          true,
          'form.placeholders.how_many',
          'form.names.smoker'
        ),
        new QuestionForm(
          'form.questions.alcohol',
          ['form.answers.yes', 'form.answers.no'],
          true,
          'form.placeholders.how_much',
          'form.names.alcohol'
        ),
      ]);
  
      formDefinition.push([
        new QuestionForm('form.questions.relatives', [], false, '', ''),
        new QuestionForm(
          'form.questions.dementia',
          ['form.answers.yes', 'form.answers.no'],
          true,
          'form.placeholders.specify_relative',
          'form.names.dementia'
        ),
        new QuestionForm(
          'form.questions.vascular_dementia',
          ['form.answers.yes', 'form.answers.no'],
          true,
          'form.placeholders.specify_relative',
          'form.names.vascular_dementia'
        ),
      ]);
  
      formDefinition.push([
        new QuestionForm(
          'form.questions.parkinson',
          ['form.answers.yes', 'form.answers.no'],
          true,
          'form.placeholders.specify_relative',
          'form.names.parkinson'
        ),
        new QuestionForm(
          'form.questions.diabetes',
          ['form.answers.yes', 'form.answers.no'],
          true,
          'form.placeholders.specify_relative',
          'form.names.diabetes2'
        ),
        new QuestionForm(
          '',
          ['form.answers.insulin', 'form.answers.non-insulin'],
          false,
          '',
          'form.names.insulin',
          { radioRequired: false, textRequired: false }
        ),
      ]);
  
      formDefinition.push([
        new QuestionForm(
          'form.questions.other',
          ['form.answers.yes', 'form.answers.no'],
          true,
          'form.placeholders.which',
          'form.names.other'
        ),
        new QuestionForm(
          'form.questions.medication',
          [],
          true,
          '',
          'form.names.medication',
          { radioRequired: false, textRequired: true }
        ),
      ]);
  
      return formDefinition;
  }