import { QuestionImgToNameAndProfession } from '@/models/QuestionImgToNameAndProfession';

export class AnswerImgToNameAndProfession {
  correctName: string;
  correctJob: string;
  isNameCorrect = 0;
  isJobCorrect = 0;
  type = 'ImgToNameAndProfession';

  fullAnswer: string;

  constructor(
    question: QuestionImgToNameAndProfession,
    userAnswers: string[]
  ) {
    const correctPerson = question.person.getReal();
    

    const words: string[] = [];

    this.correctName = correctPerson.name;
    this.correctJob = correctPerson.job;

    userAnswers.forEach((sentence: string) => {
      const splitedSentence = sentence.split(' ');
      if (splitedSentence.length > 0) {
        words.push(splitedSentence[0]);
      }
      if (splitedSentence.length > 1) {
        words.push(splitedSentence[1]);
      }
    });

    this.fullAnswer = words.toString();

    words.forEach((element: string) => {
      if (
        element === correctPerson.name.toLowerCase()
      ) {
        this.isNameCorrect = 1;
      }

      if (element === correctPerson.job.toLowerCase()) {
        this.isJobCorrect = 1;
      }
    });
  }
}
