export class QuestionForm {
    validators = {radioRequired: true, textRequired: false};

    constructor (
        public text: any, 
        public options: string[],
        public inputText: boolean,
        public placeholder: string,
        public name: string,
        public iValidators: {textRequired?: boolean, radioRequired?: boolean} = {},
    ) {
        this.validators = {...this.validators, ...iValidators};
    }
}