
export class Validation {
    static number(test: string | null) {
        return test != null && (Number(test) > 0 || test === "0");
    }

    static string(test: string | null) {
        return test != null && test.match(/^[0-9a-z]+$/);
    }

    static required(test: string | null) {
        return test != null && test != "";
    }

    static email(test: string | null) {
        const regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return test != null && test.match(regexp);
    }
}