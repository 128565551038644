<template>
  <ion-page>
    <ion-content>
      <ion-grid>
        <ion-row class="ion-text-center ion-align-items-center min_full_height">
          <ion-col>
            <ion-row>
              <ion-col size-md="10" offset-md="1">
                {{ $t("final.congrats_message") }}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size-md="10" offset-md="1">
                {{ $t("final.code_message") }}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="code" size-md="10" offset-md="1">
                {{ currentCode }}
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size-md="10" offset-md="1">
                {{ $t("final.contact") }}
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>

      <back-logos></back-logos>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonComponentsMixin } from "@/mixins/IonComponentsMixin";
import BackLogos from "@/components/BackLogos";
import { controller } from "@/services/Controller";

export default defineComponent({
  name: "FinishedView",
  components: {
    BackLogos
  },
  mixins: [IonComponentsMixin],
  data() {
    return {
      currentCode: controller.code
    };
  },
  methods: {},
});
</script>

<style lang="less" scoped>
.code {
  font-weight: bold;
}
</style>