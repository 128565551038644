import { createI18n } from 'vue-i18n'
import { storage } from '@/services/Storage';

export let i18n: any;

export async function setupI18n() {
  let locale = await storage?.get('locale');
  if(locale == null) {
    locale = 'es';
  }

  const options = {
    locale,
    globalInjection: true,
    messages: {
      en: await loadLocaleMessages('en'),
      es: await loadLocaleMessages('es'),
      ca: await loadLocaleMessages('ca')
    }
  }

  i18n = createI18n(options);
  await setI18nLanguage(options.locale);
  return i18n;
}

export function t(lang: string) {
  return i18n.global.t(lang);
}

export async function setI18nLanguage(locale: string) {
  await loadLocaleMessages(locale);

  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else if(i18n.global) {
    i18n.global.locale.value = locale;
  }
  else {
    i18n.locale = locale; 
  }

  await storage?.set('locale', locale);

  const body = document.querySelector('html');
  if(body) body.setAttribute('lang', locale);
}

export async function loadLocaleMessages(locale: string) {
  // load locale messages with dynamic import
  const messages = await fetch(
    `/assets/i18n/${locale}.json`
  );

  return await messages.json();
}

export function getCurrentLocale() {
  return i18n.global.locale;
}
