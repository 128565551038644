import { Question } from '@/models/Question';
import { QuestionForm } from '@/models/QuestionForm';
import { QuestionWait } from '@/models/QuestionWait';

export class QuestionBreakForm extends Question {

  constructor(public questionWait: QuestionWait, public questions: QuestionForm[][]) {
      super();
  }

  getComponent(): string {
    return 'BreakForm';
  }
}